import { useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';

interface UseCarouselOptions<T> {
	items: T[];
}

interface UseCarouselResult {
	carouselElRef: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
	itemRefs: React.MutableRefObject<(HTMLDivElement | null)[]>;
	scrollToNext: () => void;
	scrollToPrevious: () => void;
}

export function useCarousel<T>({ items }: UseCarouselOptions<T>): UseCarouselResult {
	const [carouselEl, setCarouselEl] = useState<HTMLDivElement | null>(null);
	const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

	useEffect(() => {
		itemRefs.current = Array(items.length).fill(null);
	}, [items.length]);

	const visibleItemRefs = useRef<HTMLDivElement[]>([]);
	const observerRef = useRef<IntersectionObserver | null>(null);
	const currentIndex = useRef(0);
	const isScrolling = useRef(false);

	const scrollToIndex = useCallback(
		(index: number) => {
			if (isScrolling.current || !carouselEl) return;
			const itemEl = itemRefs.current[index];
			if (itemEl) {
				currentIndex.current = index;
				carouselEl.scrollTo({ left: itemEl.offsetLeft, behavior: 'smooth' });
			}
		},
		[carouselEl],
	);

	useEffect(() => {
		const resizeHandler = () => {
			if (carouselEl) {
				scrollToIndex(currentIndex.current);
			}
		};

		window.addEventListener('resize', resizeHandler);

		observerRef.current = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				const target = entry.target as HTMLDivElement;
				if (entry.isIntersecting) {
					if (!visibleItemRefs.current.includes(target)) {
						visibleItemRefs.current.push(target);
					}
				} else {
					visibleItemRefs.current = visibleItemRefs.current.filter((ref) => ref !== target);
				}
				visibleItemRefs.current.sort((a, b) => a.offsetLeft - b.offsetLeft);
			});
		});

		return () => {
			observerRef.current?.disconnect();
			window.removeEventListener('resize', resizeHandler);
		};
	}, [carouselEl, scrollToIndex]);

	const onScrollEndDebounced = useRef(
		debounce(() => {
			isScrolling.current = false;

			if (!carouselEl) return;

			const scrollLeft = carouselEl.scrollLeft;
			let closestItemEl: HTMLDivElement | null = null;
			let closestItemIndex: number | null = null;

			itemRefs.current.forEach((itemEl, index) => {
				if (!itemEl) return;
				if (
					closestItemEl === null ||
					Math.abs(itemEl.offsetLeft - scrollLeft) < Math.abs(closestItemEl.offsetLeft - scrollLeft)
				) {
					closestItemEl = itemEl;
					closestItemIndex = index;
				}
			});

			if (closestItemEl && closestItemIndex !== null) {
				if (scrollLeft !== (closestItemEl as HTMLDivElement).offsetLeft) {
					scrollToIndex(closestItemIndex);
				}
			}
		}, 50),
	).current;

	const scrollToNext = () => {
		if (!carouselEl) return;

		const firstVisibleItem = visibleItemRefs.current[0];
		const lastVisibleItem = visibleItemRefs.current[visibleItemRefs.current.length - 1];

		if (lastVisibleItem === itemRefs.current[itemRefs.current.length - 1]) {
			scrollToIndex(0);
		} else {
			scrollToIndex(itemRefs.current.indexOf(firstVisibleItem) + 1);
		}
	};

	const scrollToPrevious = () => {
		if (!carouselEl) return;

		const firstVisibleItem = visibleItemRefs.current[0];

		if (firstVisibleItem === itemRefs.current[0]) {
			scrollToIndex(itemRefs.current.length - 1);
		} else {
			scrollToIndex(itemRefs.current.indexOf(firstVisibleItem) - 1);
		}
	};

	useEffect(() => {
		const onScroll = () => {
			if (!carouselEl) return;
			isScrolling.current = true;
			onScrollEndDebounced();
		};

		if (carouselEl) {
			carouselEl.addEventListener('scroll', onScroll);

			const observer = observerRef.current;
			itemRefs.current.forEach((itemEl) => {
				if (itemEl) {
					observer?.observe(itemEl);
				}
			});

			return () => {
				carouselEl.removeEventListener('scroll', onScroll);
				observer?.disconnect();
			};
		}
	}, [carouselEl, onScrollEndDebounced, itemRefs]);

	return {
		carouselElRef: setCarouselEl,
		itemRefs,
		scrollToNext,
		scrollToPrevious,
	};
}
